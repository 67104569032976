import { useCallback, useEffect, useState } from "react";
import * as actions from "../redux/MemberAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import { TransferDialog } from "./transferDialog/TransferDialog";
import { CreateGameIdDialog } from "./CreateGameIdDialog";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";

export const MemberGameList = () => {
  const { memberId } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selCompanyName, setSelCompanyName] = useState(null);
  const [selBalance, setSelBalance] = useState(null);
  const [selCompanyCode, setSelCompanyCode] = useState(null);
  const [selGameRate, setSelGameRate] = useState(null);
  const [selGameUsername, setSelGameUsername] = useState(null);
  const [selGamePass, setSelGamePass] = useState(null);
  const [selGameUrl, setSelGameUrl] = useState(null);
  const [selGameExist, setGameExist] = useState(null);
  const memberState = useSelector(({ member }) => member, shallowEqual);
  const [swalProps, setSwalProps] = useState({});
  const [initialUsername, setInitialUsername] = useState("");
  const [initialAccUsername, setInitialAccUsername] = useState("");
  const [pathFromSearch, setPathFromSearch] = useState("");
  const [backToPagination, setBackToPagination] = useState(0);
  const [scrollPosition, setScrollPosition] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [currentAgentId, setCurrentAgentId] = useState("");
  const [currentUsername, setCurrentUsername] = useState("");
  const [memberBalancePermission, setMemberBalancePermission] = useState("");
  const [gameBalances, setGameBalances] = useState({});
  const location = useLocation();

  useEffect(() => {
    const initialUsername = location.state?.userName;
    const initialAccUsername = location.state.state?.userName;
    const pathFromSearch = location.state?.searchName;
    const backToPagination = location.state.state?.pagination;
    const scrollPosition = location.state?.scrollPosition || 0;
    const searchUsername = location.state?.searchUsername;
    const memberBalancePermission =
      location.state.state?.memberBalancePermission ||
      location.state?.memberBalancePermission;
    const currentAgentId = location.state.state?.currentAgentId;
    const currentUsername = location.state.state?.currentUsername;

    setInitialUsername(initialUsername);
    setPathFromSearch(pathFromSearch);
    setBackToPagination(backToPagination - 1);
    setScrollPosition(scrollPosition);
    setSearchUsername(searchUsername);
    setInitialAccUsername(initialAccUsername);
    setMemberBalancePermission(memberBalancePermission);
    setCurrentAgentId(currentAgentId);
    setCurrentUsername(currentUsername);

    const queryParams = {
      action: "membergamelist",
      memberid: memberId,
    };

    dispatch(actions.callMemberGameList(queryParams)).then((resp) => {
      resp = resp.data;
      const gamesWithNoBalance = resp.memberGameLists.filter(
        (game) => game.gamebalance === "--"
      );
      if (gamesWithNoBalance.length > 0) {
        gamesWithNoBalance.forEach((game) => {
          const Params = {
            action: "membertargetbalance",
            memberid: memberId,
            companycode: game.gamecode,
          };
          dispatch(actions.callMemberGameListBalance(Params)).then(
            (response) => {
              setGameBalances((prevBalances) => ({
                ...prevBalances,
                [game.gamecode]: response.data.balance,
              }));
            }
          );
        });
      }
    });
  }, [memberId]);

  const handleCreateClick = useCallback(
    (companyCode) => {
      const queryParams = {
        action: "creategameacct",
        memberid: memberId,
        companycode: companyCode,
      };

      dispatch(actions.createGameId(queryParams))
        .then((resp) => {
          if (parseInt(resp.data.code) === ApiStatusTypeEnum.SUCCESS) {
            setSwalProps({
              show: true,
              icon: "success",
              title: intl.formatMessage({ id: "success" }),
              text: intl.formatMessage({ id: "createGameIdSuccess" }),
              onConfirm: () => {
                const refreshParams = {
                  action: "membergamelist",
                  memberid: memberId,
                };

                dispatch(actions.callMemberGameList(refreshParams)).then(
                  (resp) => {
                    const gamesWithNoBalance = resp.data.memberGameLists.filter(
                      (game) => game.gamebalance === "--"
                    );
                    gamesWithNoBalance.forEach((game) => {
                      const balanceParams = {
                        action: "membertargetbalance",
                        memberid: memberId,
                        companycode: game.gamecode,
                      };
                      dispatch(
                        actions.callMemberGameListBalance(balanceParams)
                      ).then((response) => {
                        setGameBalances((prevBalances) => ({
                          ...prevBalances,
                          [game.gamecode]: response.data.balance,
                        }));
                      });
                    });
                  }
                );
              },
            });
          } else {
            setSwalProps({
              show: true,
              icon: "error",
              title: intl.formatMessage({ id: "error" }),
              text: resp.data.message,
            });
          }
        })
        .catch((error) => {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: error.message,
          });
        });
    },
    [dispatch, intl, memberId]
  );

  const customCardClass =
    "rounded d-flex flex-column align-items-center justify-content-center py-3";

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <TransferDialog
        selMemberId={memberId}
        selCompanyName={selCompanyName}
        selBalance={selBalance}
        selCompanyCode={selCompanyCode}
        selGameRate={selGameRate}
        selGameUsername={selGameUsername}
        selGameUrl={selGameUrl}
        selGamePass={selGamePass}
      />
      {/* <CreateGameIdDialog
        selMemberId={memberId}
        selCompanyCode={selCompanyCode}
        selGameExist={selGameExist}
      /> */}
      <div className="d-flex align-items-center">
        <h2>
          <i
            className="bi bi-chevron-left"
            onClick={() => {
              if (pathFromSearch == "yes") {
                navigate("/searchByUsername", {
                  state: {
                    searchUsername: searchUsername,
                  },
                });
              } else {
                navigate(`/accountList`, {
                  state: {
                    returnAgentId: currentAgentId,
                    backToPagination: backToPagination,
                    scrollPosition: scrollPosition,
                    returnUsername: currentUsername,
                  },
                });
              }
            }}
          ></i>
        </h2>
        <h2>{intl.formatMessage({ id: "gameList" })}</h2>
      </div>
      <div
        className="d-flex"
        style={{ justifyContent: "space-between", margin: "0 3vh" }}
      >
        {pathFromSearch === "yes" ? (
          <h4>{initialUsername} </h4>
        ) : (
          <h4>{initialAccUsername} </h4>
        )}
      </div>
      <div
        className="row px-1 mb-4 justify-content-between align-items-center"
        style={{ width: "95%", margin: "0 auto", paddingTop: "10px" }}
      >
        {memberState.memberGameLists &&
        memberState.memberGameLists.memberGameLists ? (
          memberState.memberGameLists.memberGameLists.length > 0 &&
          memberState.memberGameLists.memberGameLists.map((game, index) => (
            <div className="mb-3 mx-1 gamelistDiv" key={index}>
              <div
                className={`${customCardClass} cursor-pointer position-relative text-center`}
                {...(memberBalancePermission === "true" &&
                  game.gameexist === "true" && {
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#TransferModal",
                    onClick: () => {
                      setSelCompanyName(game.gamename);
                      setSelCompanyCode(game.gamecode);
                      setSelBalance(
                        game.gamebalance === "--"
                          ? gameBalances[game.gamecode]
                          : game.gamebalance
                      );
                      setSelGameRate(game.gamerate);
                      setSelGameUsername(game.gameusername);
                      setSelGamePass(game.gamepassword);
                      setSelGameUrl(game.relateurl);
                      setGameExist(game.gameexist);
                    },
                  })}
              >
                {memberBalancePermission === "true" &&
                  game.gameexist === "true" && (
                    <img
                      className="position-absolute"
                      src={toAbsoluteUrl("image/transferIcon.png")}
                      style={{
                        width: "20px",
                        right: "0.01rem",
                        top: "0.5rem",
                      }}
                    />
                  )}

                <div className="mb-3 truncate">{game.gamename}</div>
                {game.gameexist === "false" ? (
                  memberState.actionsLoading ? (
                    <div
                      className="spinner-border me-2"
                      role="status"
                      style={{ scale: "0.55" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <div
                      className="fw-bold text-prim card-button-border"
                      onClick={() => {
                        handleCreateClick(game.gamecode);
                      }}
                    >
                      {intl.formatMessage({ id: "create" })}
                    </div>
                  )
                ) : (
                  <div className="fw-bold text-prim">
                    {game.gamebalance === "--"
                      ? gameBalances[game.gamecode]
                      : game.gamebalance}
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <Skeleton height="600px" className="w-100" />
        )}
      </div>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
    </div>
  );
};
