import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/SearchAction";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import "../../agentlist/components/agentList.css";
import { TopupAndWithdrawDialog } from "../../agentlist/components/TopupAndWithdrawDialog";
import { AgentDetails } from "../../agentlist/components/AgentDetails";
import { AgentGameList } from "../../agentlist/components/AgentGameList";
import { MemberList } from "../../memberlist/components/MemberList";
import { AgentFeeChargeList } from "../../agentlist/components/AgentFeeCharge";
import { AgentLotteryList } from "../../agentlist/components/AgentLotteryList";
import { ShareDialog } from "../../agentlist/components/ShareModal";

export const SearchByUsername = () => {
  const intl = useIntl();
  const [searchName, setSearchName] = useState("");
  const [swalProps, setSwalProps] = useState({});
  const [showList, setShowList] = useState(false);
  const [selAgentId, setSelAgentId] = useState(null);
  const [selMemberId, setSelMemberId] = useState(null);
  const [selUsername, setSelUsername] = useState(null);
  const [selNickname, setSelNickname] = useState(null);
  const [selBalance, setSelBalance] = useState(null);
  const [memberSettingPermission, setMemberSettingPermission] = useState("");
  const [agentSettingPermission, setAgentSettingPermission] = useState("");
  const [creditControlPermission, setCreditControlPermission] = useState("");
  const [memberBalancePermission, setMemberBalancePermission] = useState("");
  const [agentDetailsVisible, setAgentDetailsVisible] = useState(false);
  const [memberDetailsVisible, setMemberDetailsVisible] = useState(false);
  // const [agentFeeChargeVisible, setAgentFeeChargeVisible] = useState(false);
  const [agentLotteryVisible, setAgentLotteryVisible] = useState(false);
  const [fromSide, setFromSide] = useState("");
  const [pathfromSearch, setPathfromSearch] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchState = useSelector(({ search }) => search, shallowEqual);

  useEffect(() => {
    if (location.state?.searchUsername) {
      const searchUsername = location.state.searchUsername;
      setSearchName(searchUsername);
      const queryParams = {
        action: "searchbyusername",
        searchusername: searchUsername,
      };
      dispatch(actions.callSearchList(queryParams)).then((resp) => {
        resp = resp.data;
        if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
          });
        } else {
          setShowList(true);
        }
      });
    }
  }, [dispatch, location]);

  useEffect(() => {
    const storedUserPermission = JSON.parse(
      localStorage.getItem("permissions")
    );
    if (storedUserPermission) {
      storedUserPermission.forEach((permission) => {
        switch (permission.permissioncode) {
          case "membersetting":
            setMemberSettingPermission(permission.allow);
            break;
          case "agentsetting":
            setAgentSettingPermission(permission.allow);
            break;
          case "creditcontrol":
            setCreditControlPermission(permission.allow);
            break;
          case "memberbalancecontrol":
            setMemberBalancePermission(permission.allow);
            break;
          default:
            break;
        }
      });
    }
  }, []);
  const handleSearch = () => {
    const queryParams = {
      action: "searchbyusername",
      searchusername: searchName,
    };
    dispatch(actions.callSearchList(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      } else {
        setShowList(true);
      }
    });
  };

  const renderSearchResults = (list, type) => (
    <>
      <div className="buttonCss">{intl.formatMessage({ id: type })}</div>
      <div className="agent-container" style={{ overflowX: "auto" }}>
        <table className="agent-table">
          <thead className="agent-header">
            <tr className="agent-row">
              <th className="agent-cell hide-on-mobile col-1">
                {intl.formatMessage({ id: "no" })}
              </th>
              <th className="agent-cell col-2 fixed-column">
                {intl.formatMessage({ id: "username" })}
              </th>
              <th className="agent-cell col-2">
                {intl.formatMessage({ id: "nickname" })}
              </th>
              <th className="agent-cell col-2 text-end">
                {intl.formatMessage({ id: "balance" })}
              </th>
              <th className="agent-cell col-2">
                {intl.formatMessage({ id: "status" })}
              </th>
              <th className="agent-cell agent-col text-center">
                {intl.formatMessage({ id: "share" })}
              </th>
              <th className="agent-cell col-2">
                {intl.formatMessage({ id: "action" })}
              </th>
            </tr>
          </thead>
          <tbody className="agent-body">
            {list.length > 0 ? (
              list.map((item, index) => (
                <tr key={index} className="agent-row">
                  <td className="agent-cell hide-on-mobile col-1">
                    {index + 1}
                  </td>
                  <td className="agent-cell col-2 fixed-column">
                    {type === "agentList" ? (
                      <>
                        <button
                          className="buttonName"
                          onClick={() => {
                            navigate(`/accountList`, {
                              state: {
                                username: item.username,
                                agentId: item.agentid,
                                searchName: "yes",
                                searchUsername: searchName,
                              },
                            });
                          }}
                        >
                          {item.username}
                        </button>
                      </>
                    ) : (
                      <>{item.username}</>
                    )}
                  </td>
                  <td className="agent-cell col-2">{item.nickname}</td>
                  <td className="agent-cell col-2 text-end">{item.balance}</td>
                  <td className="agent-cell col-2">{item.status}</td>
                  <td className="agent-cell agent-col text-center">
                    {item.ispasswordnew === "true" && (
                      <i
                        className="bi bi-share"
                        data-bs-toggle="modal"
                        data-bs-target={`#ShareModal`}
                        onClick={() => {
                          if (type === "agentList") {
                            setFromSide("agent");
                            setSelAgentId(item.agentid);
                          } else if (type === "memberList") {
                            setFromSide("member");
                            setSelMemberId(item.memberid);
                          }
                        }}
                      ></i>
                    )}
                  </td>
                  <td className="agent-cell col-2 d-flex">
                    {type === "agentList" ? (
                      <>
                        <button
                          className="btn btn-primary me-1"
                          style={{
                            backgroundColor: "#00FFFF",
                            borderColor: "#00FFFF",
                            color: "black",
                          }}
                          onClick={() => {
                            setSelAgentId(item.agentid);
                            setAgentDetailsVisible(true);
                          }}
                        >
                          <i className="bi bi-exclamation-circle" />
                        </button>
                        {creditControlPermission === "true" && (
                          <>
                            <button
                              className="btn btn-primary me-1"
                              style={{
                                backgroundColor: "#00FF00",
                                borderColor: "#00FF00",
                                color: "black",
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#TopupAndWithdrawModal"
                              onClick={() => {
                                setSelAgentId(item.agentid);
                                setSelUsername(item.username);
                                setSelNickname(item.nickname);
                                setSelBalance(item.balance);
                                setPathfromSearch("yes");
                              }}
                            >
                              <i className="bi bi-wallet2" />
                            </button>
                            <button
                              className="btn btn-primary me-1"
                              style={{
                                backgroundColor: "#FFFF00",
                                borderColor: "#FFFF00",
                                color: "black",
                              }}
                              data-bs-toggle="modal"
                              data-bs-target={`#GameListModal`}
                              onClick={() => {
                                setSelAgentId(item.agentid);
                              }}
                            >
                              <i className="bi bi-list-ul" />
                            </button>
                          </>
                        )}

                        {/* <button
                          className="btn btn-primary"
                          style={{
                            backgroundColor: "#0080FF",
                            borderColor: "#0080FF",
                            color: "black",
                          }}
                          onClick={() => {
                            setSelUsername(item.username);
                            setSelAgentId(item.agentid);
                            setAgentFeeChargeVisible(true);
                          }}
                        >
                          <i className="bi bi-diagram-2" />
                        </button> */}
                        {agentSettingPermission === "true" && (
                          <button
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#0080FF",
                              borderColor: "#0080FF",
                              color: "black",
                            }}
                            onClick={() => {
                              setSelAgentId(item.agentid);
                              setAgentLotteryVisible(true);
                              setPathfromSearch("yes");
                            }}
                          >
                            <i className="bi bi-4-circle" />
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-primary me-1"
                          style={{
                            backgroundColor: "#00FFFF",
                            borderColor: "#00FFFF",
                            color: "black",
                          }}
                          onClick={() => {
                            setSelMemberId(item.memberid);
                            setMemberDetailsVisible(true);
                          }}
                        >
                          <i className="bi bi-exclamation-circle" />
                        </button>
                        <button
                          className="btn btn-primary me-1"
                          style={{
                            backgroundColor: "#00FF00",
                            borderColor: "#00FF00",
                            color: "black",
                          }}
                          onClick={() =>
                            navigate(`/memberList/gameList/${item.memberid}`, {
                              state: {
                                userName: item.username,
                                searchName: "yes",
                                searchUsername: searchName,
                                memberBalancePermission:
                                  memberBalancePermission,
                              },
                            })
                          }
                        >
                          <i className="bi bi-controller" />
                        </button>
                        <button
                          className="btn btn-primary"
                          style={{
                            backgroundColor: "#FFFF00",
                            borderColor: "#FFFF00",
                            color: "black",
                          }}
                          onClick={() =>
                            navigate(
                              `/memberList/memberReport/${item.memberid}`,
                              {
                                state: {
                                  userName: item.username,
                                  searchName: "yes",
                                  searchUsername: searchName,
                                },
                              }
                            )
                          }
                        >
                          <i className="bi bi-clipboard2-data" />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="oRecordDiv">
                <td colSpan="5">
                  <div className="no-record-wrapper">
                    <i className="bi bi-search fs-1 mb-3" />
                    <div className="text-font2">
                      {intl.formatMessage({ id: "noRecordFound" })}
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <div style={{ width: "95%", margin: "5px auto" }}>
      <div>
        <h2>{intl.formatMessage({ id: "searchUsername" })}</h2>
      </div>
      <SweetAlert2
        {...swalProps}
        customClass="custom-swal"
        onResolve={() => setSwalProps({})}
        backdrop={false}
      />
      <ShareDialog
        selAgentId={selAgentId}
        selMemberId={selMemberId}
        fromSide={fromSide}
      />
      <TopupAndWithdrawDialog
        setSwalProps={setSwalProps}
        pathfromSearch={pathfromSearch}
        searchName={searchName}
        selAgentId={selAgentId}
        selUsername={selUsername}
        selNickname={selNickname}
        selBalance={selBalance}
      />
      <AgentDetails
        selAgentId={selAgentId}
        isAgentDetailsVisible={agentDetailsVisible}
        agentSettingPermission={agentSettingPermission}
        onClose={() => setAgentDetailsVisible(false)}
      />
      <AgentGameList selAgentId={selAgentId} />
      <MemberList
        selMemberId={selMemberId}
        isMemberDetailsVisible={memberDetailsVisible}
        memberSettingPermission={memberSettingPermission}
        onClose={() => setMemberDetailsVisible(false)}
      />
      {/* <AgentFeeChargeList
        setSwalProps={setSwalProps}
        selAgentId={selAgentId}
        isAgentFeeChargeVisible={agentFeeChargeVisible}
        selUsername={selUsername}
        onClose={() => setAgentFeeChargeVisible(false)}
      /> */}
      <AgentLotteryList
        setSwalProps={setSwalProps}
        selAgentId={selAgentId}
        pathfromSearch={pathfromSearch}
        searchName={searchName}
        isAgentLotteryVisible={agentLotteryVisible}
        onClose={() => setAgentLotteryVisible(false)}
      />
      <div className="d-flex">
        <div style={{ width: "80%", marginRight: "5px" }}>
          <input
            name="searchName"
            type="text"
            className="form-control"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            placeholder={intl.formatMessage({ id: "username" })}
          />
        </div>
        <div>
          <button className="btn btn-primary" onClick={handleSearch}>
            {intl.formatMessage({ id: "search" })}
          </button>
        </div>
      </div>
      {showList && (
        <div>
          {searchState.searchLists?.agentList?.agentLists ? (
            renderSearchResults(
              searchState.searchLists.agentList.agentLists,
              "agentList"
            )
          ) : (
            <Skeleton height="600px" className="w-100" />
          )}
          {searchState.searchLists?.memberList?.memberLists ? (
            renderSearchResults(
              searchState.searchLists.memberList.memberLists,
              "memberList"
            )
          ) : (
            <Skeleton height="600px" className="w-100" />
          )}
        </div>
      )}
    </div>
  );
};
